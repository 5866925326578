export const environment = {
	production: true,
	firebase: {
		apiKey: 'AIzaSyDb-33sVwyK1vP6aQQmtm6D7Ggo3XcpOJo',
		authDomain: 'rubiks-timesheets-live.firebaseapp.com',
		databaseURL: 'https://rubiks-timesheets-live.firebaseio.com',
		projectId: 'rubiks-timesheets-live',
		storageBucket: 'rubiks-timesheets-live.appspot.com',
		messagingSenderId: '102154721554',
		appId: '1:102154721554:web:f168f84f44944dcce67bcb'
	},
};
