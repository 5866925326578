<div class="row pt-4">
	<div class="col">
		<div class="card">
			<div class="card-body">
				<h5 class="card-title">History</h5>
				<hr />
				<!-- {{historyData | json}} -->
				<div class="row">
					<div class="col">
						<ul *ngIf="historyData?.length > 0; else noHistory">
							<li *ngFor="let item of historyData">
								<div class="row">
									<div class="col">
										<small>{{ item.createdByName }} - {{ item.description }}</small>
									</div>
									<div class="col-3 pull-right">
										<small>{{ item.created.toDate() | date: 'fullDate' }}</small>
									</div>
								</div>
							</li>
						</ul>
						<ng-template #noHistory>
							<div class="row">
								<div class="col text-center">No results found.</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
