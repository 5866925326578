import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeComponent } from './theme.component';
import { ThemeRoutingModule } from './theme-routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LayoutModule } from './layouts/layout.module';
import { CoreModule } from '../_shared/core.module';

@NgModule({
	declarations: [ThemeComponent],
	imports: [CommonModule, ThemeRoutingModule, CoreModule.forRoot(), NgxPermissionsModule.forChild(), LayoutModule],
	exports: [ThemeComponent],
	bootstrap: [ThemeComponent],
})
export class ThemeModule {}
