import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/auth/_services';

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	constructor(public db: AngularFirestore, private authService: AuthenticationService) {}

	fetchNotes(data) {
		console.log(data);
		return this.db
			.collection('notes', (ref) =>
				ref
					.where('type', '==', data.type)
					.where('date', '==', data.date)
					.where('projectId', '==', data.projectId)
					.where('timecodeId', '==', data.timecodeId)
					.orderBy('created', 'desc')
			)
			.valueChanges({ idField: 'id' });
	}

	addNote(data) {
		data.active = true;
		data.created = new Date();
		data.createdBy = this.authService.userDetails.uid;
		data.createdByName = `${this.authService.userDetails.firstname} ${this.authService.userDetails.surname}`;
		return this.db.collection('notes').add(data);
	}
}
