import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PermissionsResolverService } from '../_resolvers/permissions.resolver';
import { ThemeResolverService } from '../_resolvers/theme.resolver';
import { ThemeComponent } from './theme.component';

const themeRoutes: Route[] = [
	{
		path: '',
		component: ThemeComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./pages/pages.module').then((mod) => mod.PagesModule),
				resolve: {
					theme: ThemeResolverService,
					perms: PermissionsResolverService,
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(themeRoutes)],
	exports: [RouterModule],
})
export class ThemeRoutingModule {}
