import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs';

type UserFields = 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
	selector: 'app-set-password',
	templateUrl: './auth-set-password.component.html',
})
export class AuthSetPasswordComponent implements OnInit {
	authSubscription: Subscription;

	passwordResetForm: FormGroup;
	newUser = false; // to toggle login or signup form
	passReset = false; // set to true when password reset is triggered
	message = { message: '', type: '' };

	formErrors: FormErrors = {
		password: '',
	};
	validationMessages = {
		password: {
			required: 'Password is required.',
		},
	};

	// NEW USER REGISTERED REDIRECT
	decodeUserPassword: any;
	userEmail: any;

	constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router, private route: ActivatedRoute) {}

	ngOnInit() {
		this.buildForm();
		this.authSubscription = this.auth.user.subscribe((userDetails) => {
			if (userDetails) {
				this.userEmail = userDetails.email;
				this.route.params.subscribe((params: Params) => {
					if (params['uid']) {
						this.decodeUserPassword = atob(params['uid']);
					}
				});
			}
		});
	}

	changePassword() {
		this.auth.changePassword(this.userEmail, this.decodeUserPassword).then((data: any) => {
			if (!data.user) {
				this.message.message = data.message;
				this.message.type = 'error';
			} else {
				this.auth.updateUserPassword(data.user, this.passwordResetForm.value['password']).then((passwordData: any) => {
					if (passwordData.type === 'success') {
						this.message.message = 'Password set successfully';
						this.message.type = 'success';
						this.router.navigate(['/dashboard']);
					} else {
						this.message.message = passwordData.error.message;
						this.message.type = 'error';
					}
				});
			}
		});
	}

	buildForm() {
		this.passwordResetForm = this.fb.group({
			password: ['', [Validators.required]],
		});
		this.passwordResetForm.valueChanges.subscribe((data) => this.onValueChanged(data));
		this.onValueChanged();
	}

	reset() {
		this.buildForm();
	}

	// Updates validation state on form changes.
	onValueChanged(data?: any) {
		if (!this.passwordResetForm) {
			return;
		}
		const form = this.passwordResetForm;
		for (const field in this.formErrors) {
			if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && field === 'password') {
				// clear previous error message (if any)
				this.formErrors[field] = '';
				const control = form.get(field);
				if (control && control.dirty && !control.valid) {
					const messages = this.validationMessages[field];
					if (control.errors) {
						for (const key in control.errors) {
							if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
								this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
							}
						}
					}
				}
			}
		}
	}
}
