import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './_components/auth-login/auth-login.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthRegisterComponent } from './_components/auth-register/auth-register.component';
import { AuthPasswordResetComponent } from './_components/auth-password-reset/auth-password-reset.component';
import { AuthVerificationComponent } from './_components/auth-verification/auth-verification.component';
import { VerificationPageComponent } from './pages/verification-page/verification-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password/reset-password.component';
import { AuthSetPasswordComponent } from './_components/auth-set-password/auth-set-password.component';

@NgModule({
	declarations: [
		AuthComponent,
		AuthLoginComponent,
		AuthPasswordResetComponent,
		AuthRegisterComponent,
		AuthVerificationComponent,
		LoginPageComponent,
		RegisterPageComponent,
		VerificationPageComponent,
		ResetPasswordPageComponent,
		AuthSetPasswordComponent,
	],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AuthRoutingModule],
	providers: [AuthGuard, AuthenticationService],
	exports: [AuthLoginComponent, AuthPasswordResetComponent, AuthRegisterComponent, AuthVerificationComponent, AuthSetPasswordComponent],
})
export class AuthModule {}
