import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../_services';

@Component({
	selector: 'app-register-page',
	templateUrl: './register-page.component.html',
	styleUrls: ['../../auth.component.scss', './register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
	authSubscription: Subscription;
	showHide: boolean = false;
	passReset: boolean = false;

	constructor(public auth: AuthenticationService, private router: Router) {}

	ngOnInit() {
		this.authSubscription = this.auth.user.subscribe((userDetails) => {
			if (userDetails) {
				this.router.navigate(['/dashboard']);
			}
		});
	}
}
