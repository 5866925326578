<div class="todo-list">
	<div class="todo-filter">
		<button class="btn btn-sm" [ngClass]="todoShow === 'Pending' ? 'btn-warning' : 'btn-outline-warning'" (click)="todoShow = 'Pending'">Pending</button>
		<button class="btn btn-sm" [ngClass]="todoShow === 'Completed' ? 'btn-success' : 'btn-outline-success'" (click)="todoShow = 'Completed'">Completed</button>
	</div>
	<div *ngIf="todotasks.length === 0" class="mt-2">No pending items to display</div>
	<ng-template ngFor let-todo [ngForOf]="todotasks" let-i="index">
		<div class="row" *ngIf="todo?.status === todoShow">
			<div class="col-md-12">
				<div class="todo-item">
					<div class="description">
						<span class="badge bg-dark font-light">
							<small>{{ todo?.created['seconds'] * 1000 | date: 'dd/MM/yyyy HH:mm' }}</small>
						</span>
						<br />
						{{ todo?.title }}
						<span *ngIf="todo?.buttonLink">
							<br />
							<button class="btn btn-outline-primary btn-sm mt-2" [routerLink]="todo?.buttonLink">{{ todo?.buttonText }}</button>
						</span>
					</div>
					<div class="custom-control custom-checkbox">
						<input
							type="checkbox"
							title="Complete Task"
							class="custom-control-input"
							(change)="updateTask(todo, todoShow)"
							[checked]="todo?.status === 'Completed'"
							[id]="i"
							name="example1"
						/>
						<label title="Complete Task" class="custom-control-label" [for]="i"></label>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>
<div *ngIf="showCreateNew" class="row mt-3">
	<div class="col-md-12">
		<div class="input-group">
			<input type="text" class="form-control" [(ngModel)]="todotask.title" placeholder="Create new task" (keyup.enter)="todotask.title ? addTask() : ''" />
			<div class="input-group-append">
				<button class="btn btn-success" [disabled]="!todotask.title" type="button" (click)="addTask()"><i class="fa fa-plus"></i></button>
			</div>
		</div>
	</div>
</div>
