import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/auth/_services';
import { TodoTask } from '../_models/tasks-todo.model';

@Injectable({
	providedIn: 'root',
})
export class TasksService {
	constructor(public db: AngularFirestore, private authService: AuthenticationService) {}

	fetchTodoTasks(type: string) {
		return this.db
			.collection('tasks', (ref) => ref.where('users', 'array-contains', this.authService.userDetails.uid).where('type', '==', type).orderBy('created', 'desc'))
			.valueChanges({ idField: 'id' });
	}

	fetchTodoSectionTasks(section: string, sectionId: string) {
		return this.db
			.collection('tasks', (ref) => ref.where('type', '==', section).where('sectionId', '==', sectionId).orderBy('created', 'desc'))
			.valueChanges({ idField: 'id' });
	}

	addTodoTask(type: string, data: TodoTask) {
		data.active = true;
		data.created = new Date();
		data.createdBy = this.authService.userDetails.uid;
		data.status = 'Pending';
		data.type = type;
		return this.db.collection('tasks').add(data);
	}

	addToDoSectionTask(section: string, sectionId: string, data: TodoTask) {
		const saveData = {
			...data,
			active: true,
			created: new Date(),
			createdBy: this.authService.userDetails.uid,
			status: 'Pending',
			type: section,
			sectionId
		};

		return this.db.collection('tasks').add(saveData);
	}

	updateTask(data: TodoTask, id: string) {
		return this.db.doc(`tasks/${id}`).set(data, { merge: true });
	}
}
