import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AuthModule } from '../auth/auth.module';
import { AuthenticationService } from '../auth/_services';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './_modules/material.module';
import { DataTablesModule } from 'angular-datatables';
import { SharedComponentsModule } from './_components/shared-components.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { AuditLogService } from './_services/audit-log.service';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from './_formatters/custom-ngbDateParserFormatter';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		AuthModule,
		NgxPermissionsModule,
		RouterModule,
		HttpClientModule,
		MaterialModule,
		DataTablesModule,
		NgxChartsModule,
		RoundProgressModule,
		SharedComponentsModule,
		NgbModule,
	],
	exports: [
		AuthModule,
		NgxPermissionsModule,
		RouterModule,
		HttpClientModule,
		MaterialModule,
		DataTablesModule,
		NgxChartsModule,
		RoundProgressModule,
		SharedComponentsModule,
		NgbModule,
	],
	providers: [DatePipe, { provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('longDate') }],
})
export class CoreModule {
	static forRoot() {
		return {
			ngModule: CoreModule,
			providers: [AuthenticationService, AuditLogService],
		};
	}
}
