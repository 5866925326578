import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { TimeCodesAddComponent } from './time-codes-add/time-codes-add.component';

@NgModule({
	declarations: [TimeCodesAddComponent],
	imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, DataTablesModule],
	exports: [TimeCodesAddComponent],
})
export class SharedTimeCodesModule {}
