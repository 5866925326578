import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeCodesService } from 'src/app/theme/pages/time-codes/time-codes.service';

export interface DialogData {
	projectId: string;
}

@Component({
	selector: 'app-time-codes-add',
	templateUrl: './time-codes-add.component.html',
	styleUrls: ['./time-codes-add.component.scss'],
})
export class TimeCodesAddComponent implements OnInit {
	@Input() projectId: string;
	@Output() cancel = new EventEmitter<boolean>();

	timeCodesForm: FormGroup;
	submitting = false;

	constructor(private formBuilder: FormBuilder, private timeCodesService: TimeCodesService) {}

	ngOnInit(): void {
		this.timeCodesForm = this.formBuilder.group({
			code: ['', [Validators.required]],
			description: ['', [Validators.required]],
			noteRequired: [false],
		});
	}

	addTimeCode() {
		this.submitting = true;
		const formValue = this.timeCodesForm.value;
		formValue.project = this.projectId;
		this.timeCodesService.addTimeCode(formValue).then(() => {
			this.cancel.emit(true);
			this.timeCodesForm.reset();
			this.timeCodesForm.markAsUntouched();
			this.submitting = false;
		});
	}
}
