<div class="wrapper" *ngIf="!loadingData; else showLoader" [ngClass]="{ 'min-nav': sideNavService.hideSideNav }">
	<app-aside-nav></app-aside-nav>
	<app-quick-panel></app-quick-panel>
	<div class="page-wrapper">
		<app-authorised-top-nav></app-authorised-top-nav>
		<router-outlet></router-outlet>
		<app-footer></app-footer>
	</div>
</div>
<ng-template #showLoader>
	<div id="preloader">
		<div id="loader"></div>
	</div>
</ng-template>
