import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/auth/_services';
import { User } from 'src/app/_shared/_models/user.model';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxPermissionsService } from 'ngx-permissions';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	user: User;

	constructor(
		public db: AngularFirestore,
		private authService: AuthenticationService,
		private functions: AngularFireFunctions,
		private permissionsService: NgxPermissionsService,
		private storage: AngularFireStorage
	) {}

	fetchUser(userId: string = this.authService.userDetails.uid) {
		return this.db.doc(`users/${userId}`).valueChanges();
	}

	fetchUsers() {
		return this.db.collection('users', (ref) => ref.orderBy('firstname', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchUserProjects(userId: string) {
		return this.db
			.collection('projects', (ref) => ref.where('active', '==', true).where('users', 'array-contains', userId).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	fetchUserTeams(userId: string) {
		return this.db
			.collection('teams', (ref) => ref.where('active', '==', true).where('users', 'array-contains', userId).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	fetchUserNotifications() {
		return this.db
			.collection('tasks', (ref) =>
				ref
					.where('users', 'array-contains', this.authService.userDetails.uid)
					.where('active', '==', true)
					.where('type', '==', 'notifications')
					.where('status', '==', 'Pending')
			)
			.valueChanges({ idField: 'id' });
	}

	fetchAdmins() {
		return this.db
			.collection('users')
			.ref.where('permissions', 'array-contains', 'admin')
			.get()
			.then((users: any) => {
				return users.docs.map((user: any) => {
					return { id: user.id, ...user.data() };
				});
			});
	}

	addUser(user: User) {
		// PARAMS
		const params = this.setCallableParams(user, `addUser`);

		// CLOUD FUNCTIONS CALL
		const callable = this.functions.httpsCallable('runCallRequest');
		const data = callable(params);
		return data;
	}

	updateUser(userId: string, data: User) {
		return this.db.doc(`users/${userId}`).set(data, { merge: true });
	}

	// SET DEFAULT CALLABLE PARAMS
	setCallableParams = (data, type) => ({
		data,
		type,
	});

	deleteUser(uid) {
		return this.db.doc('users/' + uid).delete();
	}

	updatePermissions(user) {
		this.permissionsService.loadPermissions(user.permissions);
		localStorage.setItem('permissions', JSON.stringify(user.permissions));

		return this.db.doc(`users/${user.uid}`).set({ permissions: user.permissions }, { merge: true });
	}

	// IMAGE UPLOAD
	uploadImage(upload = null, imageName: string, loggedInUser: User) {
		const fileName = `${new Date().getTime()}_${imageName}`;
		const path = `users/${loggedInUser.uid}/files/${fileName}.png`;
		const task = this.storage.upload(path, upload);
		const ref = this.storage.ref(path);

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe((url) => {
							const file = {
								photoURL: url,
							};

							return this.saveUploadDataFirestore(file, loggedInUser)
								.then(() => {
									return resolve('');
								})
								.catch((err) => {
									return reject('Failed to save data to firestore: ' + err);
								});
						});
					})
				)
				.subscribe();
		});
	}

	private saveUploadDataFirestore(file: any, loggedInUser: User) {
		const updateUser = this.db.doc(`users/${loggedInUser.uid}`).update(file);

		return Promise.all([updateUser]);
	}
}
