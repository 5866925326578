import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/auth/_services';
import { TasksService } from 'src/app/_shared/_services/tasks.service';
import { UsersService } from '../../users/_services/users.service';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class TimesheetsService {
	constructor(public db: AngularFirestore, public authService: AuthenticationService, public usersService: UsersService, private tasksService: TasksService) { }

	getCurrentTimesheet(year, week, userId: string) {
		return this.db
			.collection('timesheets', (ref) =>
				ref
					.where('year', '==', year)
					.where('week', '==', week)
					.where('createdBy', '==', userId === 'auth' ? this.authService.userDetails.uid : userId)
					.orderBy('order', 'asc')
			)
			.valueChanges({ idField: 'id' });
	}

	async setCurrentTimesheet(year, week, userId: string, date) {
		const lastWeek = moment(date).subtract(1, 'weeks');
		const getPreviousWeek = await this.db
			.collection('timesheets')
			.ref.where('year', '==', +lastWeek.isoWeekYear())
			.where('week', '==', +lastWeek.isoWeek())
			.where('createdBy', '==', userId === 'auth' ? this.authService.userDetails.uid : userId)
			.orderBy('order', 'asc')
			.get();
		if (getPreviousWeek.empty) {
			return Promise.resolve('No entries from last week');
		} else {
			getPreviousWeek.forEach((record: any) => {
				const dayDates = record.data().dayDates;
				const dayHours = record.data().dayHours;
				const dayNotes = record.data().dayNotes;
				for (let [key, value] of Object.entries(dayDates)) {
					const date = new Date(value['seconds'] * 1000);
					dayDates[key] = new Date(date.setDate(date.getDate() + 7));
				}
				for (let [key] of Object.entries(dayHours)) {
					dayHours[key] = 0;
				}
				for (let [key] of Object.entries(dayNotes)) {
					dayNotes[key] = 0;
				}
				const newRecordData = { ...record.data(), dayDates, dayHours, dayNotes, week, year, submitted: false, id: '' };
				this.addTimesheetRecord(newRecordData);
			});
		}
	}

	getAllUsersTimesheets() {
		return this.db
			.collection('timesheets', (ref) => ref.where('createdBy', '==', this.authService.userDetails.uid).where('order', '==', 0).orderBy('dayDates.monday', 'desc'))
			.valueChanges({ idField: 'id' });
	}

	public getUserTimesheets() {
		return this.db
			.collection('timesheets', (ref) => ref.where('createdBy', '==', this.authService.userDetails.uid).orderBy('dayDates.monday', 'desc'))
			.valueChanges({ idField: 'id' });
	}

	fetchAuthUserTimesheets() {
		return this.db.collection('timesheets', (ref) => ref.where('createdBy', '==', this.authService.userDetails.uid)).valueChanges({ idField: 'id' });
	}

	addTimesheetRecord(data: any) {
		data.active = true;
		data.created = new Date();
		data.createdBy = this.authService.userDetails.uid;
		data.validated = false;
		return this.db.collection('timesheets').add(data);
	}

	deleteTimesheetRecord(id) {
		return this.db.doc(`timesheets/${id}`).delete();
	}

	updateTimesheetRecord(values, id) {
		return this.db.doc(`timesheets/${id}`).set(values, { merge: true });
	}

	fetchAllProjects() {
		return this.db.collection('projects', (ref) => ref.where('active', '==', true).orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchUserProjects() {
		return this.db
			.collection('projects', (ref) => ref.where('active', '==', true).where('users', 'array-contains', this.authService.userDetails.uid).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	fetchGeneralProjects() {
		return this.db.collection('projects-general', (ref) => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchUserProjectsTimeCodes() {
		return this.db.collection('time-codes').valueChanges({ idField: 'id' });
	}

	fetchApprovalTimesheets() {
		return this.db
			.collection('timesheets', (ref) => ref.where('active', '==', true).where('submitted', '==', true).where('validated', '==', false))
			.valueChanges({ idField: 'id' });
	}

	checkUserCanApprove(createdBy: string) {
		// CHECK IF USER IS ADMIN
		if (this.authService.userDetails.permissions.indexOf('admin') === -1) {
			return false;
		}

		// CHECK IF USER CREATED THE TIMESHEET
		if (createdBy === this.authService.userDetails.uid) {
			return false;
		}

		return true;
	}

	fetchUserDetails(userId: string) {
		return this.db
			.doc(`users/${userId}`)
			.ref.get()
			.then((user: any) => {
				return {
					id: user.id,
					...user.data(),
				};
			});
	}

	fetchUsersList() {
		return this.db
			.collection(`users`)
			.ref.get()
			.then((users: any) => {
				const usersList = {};
				users.docs.map((user: any) => {
					usersList[user.id] = `${user.data().firstname} ${user.data().surname}`;
				});

				return usersList;
			});
	}

	createNotification(year: string, month: string) {
		this.usersService.fetchAdmins().then((users: any) => {
			const data = {
				title: `${this.authService.userDetails.firstname} ${this.authService.userDetails.surname} submitted his timesheet for ${year}-${month}`,
				users: users.map((user) => {
					return user.id;
				}),
				buttonText: 'View Approvals',
				buttonLink: `/timesheets/approvals`,
			};

			this.tasksService.addTodoTask('notifications', data);
		});
	}
}
