import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/_services';
import { User } from 'src/app/_shared/_models/user.model';
import { UsersService } from '../../pages/users/_services/users.service';
import { AsideNavService } from '../_services/authorised-side-nav.service';

@Component({
	selector: 'app-authorised-top-nav',
	templateUrl: './top-nav.component.html',
	styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
	// USER
	user: User;
	userSubscription: Subscription;
	userInitials: string;
	notifications = 0;

	constructor(public auth: AuthenticationService, public sideNavService: AsideNavService, public usersService: UsersService) {}

	ngOnInit() {
		this.userSubscription = this.auth.user.subscribe((userDetails) => {
			if (userDetails) {
				this.user = userDetails;
				const checkEmailAddress = this.user.email.split('@')[1];
				// this.clientsService.userDetails.next(this.user);
				this.userInitials = this.user.firstname.charAt(0) + this.user.surname.charAt(0);

				// FETCH USER NOTIFICATIONS
				this.usersService.fetchUserNotifications().subscribe((notifications) => {
					this.notifications = notifications.length;
				});
			}
		});
	}

	showNotifications() {
		this.sideNavService.setSelectedQuickPanelTab('notifications');
	}

	logout() {
		this.auth.signOut();
	}
}
