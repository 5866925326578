import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AsideNavService {
	hideSideNav = false;
	hideQuickPanel = true;
	selectedQuickPanelTab = 'To-do';

	constructor() {}

	toggleSideNav(): void {
		this.hideSideNav = !this.hideSideNav;
	}

	toggleQuickPanel(): void {
		this.hideQuickPanel = !this.hideQuickPanel;
	}

	setSelectedQuickPanelTab(tab: string) {
		console.log('quick tab', this.selectedQuickPanelTab, tab);
		this.selectedQuickPanelTab = tab;
		this.hideQuickPanel = !this.hideQuickPanel;
	}
}
