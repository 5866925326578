<div class="text-center">
	<span *ngIf="!userVerifying; else verifyingEmail">
		<span *ngIf="userVerificationSuccessfull; else verificationFailed">
			Thank you for registering on, your email address has been successfully verified
			<hr />
			<button *ngIf="!user" type="button" class="btn btn-primary btn-dark mt-2" routerLink="/login">Login</button>
			<button *ngIf="authService.user | async" type="button" class="btn btn-dark mt-2" routerLink="/dashboard">Dashboard</button>
		</span>
		<ng-template #verificationFailed> Email address could not be verified, please try again or contact a adminsitrator </ng-template>
	</span>
	<ng-template #verifyingEmail>
		<div class="text-center">
			Verifying Email<br />
			<br />
			<span class="fa fa-spinner fa-spin"></span>
		</div>
	</ng-template>
</div>
