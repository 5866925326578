<nav class="navbar navbar-light bg-white">
	<app-aside-nav-toggler></app-aside-nav-toggler>
	<ul class="nav">
		<li class="nav-item">
			<button
				type="button"
				class="btn btn-outline-primary btn-sm mr-4"
				title="View Notifications"
				[matBadge]="notifications ? notifications : ''"
				(click)="showNotifications()"
			>
				<span class="fa fa-bell-o"></span>
			</button>
		</li>
		<li class="nav-item dropdown" style="line-height: 0.5">
			<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
				<span class="circle_container">
					<span *ngIf="!user?.photoURL" class="profile-circle">{{ userInitials }}</span>
					<img *ngIf="user?.photoURL" [src]="user?.photoURL" class="rounded-circle profile-circle" alt="Profile Image" />
				</span>
			</a>
			<div class="dropdown-menu dropdown-menu-right">
				<a routerLink="/profile" class="dropdown-item">Profile</a>
				<a routerLink="/settings" class="dropdown-item">Settings</a>
				<div class="dropdown-divider"></div>
				<a href="" class="dropdown-item" (click)="logout()">Logout</a>
			</div>
		</li>
		<li class="nav-item">
			<a class="nav-link panel-toggle" (click)="sideNavService.toggleQuickPanel()"><i class="fa fa-th-large"></i></a>
		</li>
	</ul>
</nav>
