import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { TasksService } from 'src/app/_shared/_services/tasks.service';
import { TodoTask } from 'src/app/_shared/_models/tasks-todo.model';

@Component({
	selector: 'app-to-do-project-list',
	templateUrl: './to-do-project-list.component.html',
	styleUrls: ['../to-do.scss'],
})
export class ToDoProjectListComponent implements OnInit, OnDestroy {
	sectionId: string;
	@Input() set sectionIdInput(sectionId: string) {
		this.sectionId = sectionId;
		if (sectionId) {
			this.fetchToDoList();
		}
	}
	section: string;
	@Input() set sectionInput(section: string) {
		this.section = section;
	}
	@Input() showCreateNew: boolean;

	// To do item
	todotask: TodoTask = {
		title: '',
		users: [],
	};
	// To-do list
	todoSubscription: Subscription;
	todotasks: TodoTask[] = [];
	todoShow: string;

	constructor(private tasksService: TasksService) {}

	ngOnInit() {}

	fetchToDoList() {
		this.todoShow = 'Pending';
		this.todoSubscription = this.tasksService.fetchTodoSectionTasks(this.section, this.sectionId).subscribe((todoTasks) => {
			this.todotasks = todoTasks;
		});
	}

	addTask() {
		this.tasksService.addToDoSectionTask(this.section, this.sectionId, this.todotask).then(() => {
			this.todotask.title = '';
		});
	}

	updateTask(todo, type) {
		todo.status = type === 'Pending' ? 'Completed' : 'Pending';
		this.tasksService.updateTask(todo, todo.id);
	}

	ngOnDestroy() {
		this.todoSubscription.unsubscribe();
	}
}
