import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-auth',
	template: '<router-outlet></router-outlet>',
	encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
