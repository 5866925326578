import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TimeCode } from 'src/app/_shared/_models/time-code.model';
import { AuthenticationService } from 'src/app/auth/_services';
import { combineLatest } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TimeCodesService {
	constructor(public db: AngularFirestore, private authService: AuthenticationService) {}

	fetchTimeCodes() {
		const general = this.db.collection('time-codes-general', (ref) => ref.orderBy('code', 'asc')).valueChanges({ idField: 'id' });
		const codes = this.db.collection('time-codes', (ref) => ref.orderBy('code', 'asc')).valueChanges({ idField: 'id' });
		return combineLatest([general, codes]);
	}

	fetchTimeCodesOnly() {
		return this.db.collection('time-codes', (ref) => ref.orderBy('code', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchTimeCode(id: string) {
		return this.db.doc(`time-codes/${id}`).valueChanges();
	}

	addTimeCode(data: TimeCode) {
		data.active = true;
		data.created = new Date();
		data.createdBy = this.authService.userDetails.uid;
		return this.db.collection('time-codes').add(data);
	}

	updateTimeCode(id: string, data: TimeCode) {
		return this.db.doc(`time-codes/${id}`).update(data);
	}

	fetchProjects() {
		return this.db.collection('projects').valueChanges({ idField: 'id' });
	}

	fetchProject(id: string) {
		return this.db.doc(`projects/${id}`).valueChanges();
	}
}
