import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject, Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import moment from 'moment';
import { NotesService } from './notes.service';

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
	@Input() noteInfo: any;
	@Output() cancel = new EventEmitter<boolean>();
	notes: any;
	notesForm = new FormGroup({
		note: new FormControl('', Validators.required),
	});

	submitting = false;
	subscriptions = new Subscription();

	constructor(private router: Router, private notesService: NotesService) { }

	ngOnInit() {
		this.subscriptions.add(
			this.notesService.fetchNotes(this.noteInfo).subscribe((notes) => {
				this.notes = notes;
			})
		);
	}

	addNote() {
		this.submitting = true;
		this.noteInfo.note = this.notesForm.get('note').value;
		this.notesService.addNote(this.noteInfo).then(() => {
			this.submitting = false;
			this.notesForm.reset();
		});
	}

	cancelNote() {
		this.cancel.emit(true);
	}
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
