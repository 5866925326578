import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

import { ModalService } from './modal.service';

@Component({
	selector: 'r-modal',
	templateUrl: 'modal.component.html',
	styleUrls: ['modal.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id: string;
	@Input() size: string;
	@Output() closeModal = new EventEmitter<boolean>();

	private element: any;

	constructor(private modalService: ModalService, private el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngOnInit(): void {
		// ensure id attribute exists
		if (!this.id) {
			console.error('modal must have an id');
			return;
		}

		// move element to bottom of page (just before </body>) so it can be displayed above everything else
		document.body.appendChild(this.element);

		// close modal on background click
		this.element.addEventListener('click', (el) => {
			if (el.target.className === 'r-modal') {
				this.close();
			}
		});

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modalService.add(this);
	}

	// remove self from modal service when component is destroyed
	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		document.body.classList.remove('r-modal-open');
		this.element.remove();
	}

	// open modal
	open(): void {
		this.element.classList.add('reveal');
		this.element.classList.remove('out');
		document.body.classList.add('r-modal-open');
	}

	// close modal
	close(): void {
		this.element.classList.add('out');
		document.body.classList.remove('r-modal-open');
		this.closeModal.emit(true);
	}
}
