<div class="notes-panel">
	<div *ngIf="notes?.length > 0">
		<div class="notes-list">
			<div class="note-item small" *ngFor="let note of notes">
				{{ note?.note }}
				<div class="note-info mt-1 pt-1 small text-right">{{ note?.created.toDate() | date }} | {{
					note?.createdByName }}</div>
			</div>
		</div>
	</div>
	<form [formGroup]="notesForm">
		<textarea class="form-control" formControlName="note" placeholder="Add a note"></textarea>
		<div class="row pt-3">
			<div class="col-md-12 text-right">
				<button type="button" class="btn btn-light btn-sm mr-2" (click)="cancelNote()">Close</button>
				<button [disabled]="submitting || notesForm.invalid" type="button"
					class="btn btn-outline-success btn-sm" (click)="addNote()">
					<i class="fa fa-plus"></i>
					<span *ngIf="!submitting">Add Note</span>
					<span *ngIf="submitting">Adding</span>
				</button>
			</div>
		</div>
	</form>
</div>