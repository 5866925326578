import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services';
import { take, map, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private auth: AuthenticationService, private permissionsService: NgxPermissionsService, private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.auth.user.pipe(
			take(1),
			map((user) => !!user),
			tap((loggedIn) => {
				if (!loggedIn) {
					console.log('LOGGED OUT');
					this.router.navigate(['/login']);
				} else {
					this.auth.user.subscribe((user) => {
						console.log('LOGGED IN');
						if (user) {
							// this.router.navigate(['/dashboard']);
							if (!user.emailVerified) {
								// Do something
							}
						}
					});
				}
			})
		);
	}
}
