<form [formGroup]="userRegisterForm" (ngSubmit)="signup()">
	<div class="row">
		<div class="col-md-12 text-center mb-2">
			<h2>Sign Up</h2>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<div class="input text required">
					<input name="firstname" placeholder="First Name *" class="form-control" id="firstname" formControlName="firstname" />
				</div>
			</div>
			<div *ngIf="formErrors.firstname" class="alert alert-danger" role="alert">
				{{ formErrors.firstname }}
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<div class="input text">
					<input name="surname" placeholder="Surname *" class="form-control" id="surname" formControlName="surname" />
				</div>
			</div>
			<div *ngIf="formErrors.surname" class="alert alert-danger" role="alert">
				{{ formErrors.surname }}
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<div class="input email margin-bottom-15">
					<input type="email" id="email" placeholder="Email *" class="input form-control" formControlName="email" />
				</div>
			</div>
			<div *ngIf="formErrors.email" class="alert alert-danger" role="alert">
				{{ formErrors.email }}
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<div class="input password required">
					<input name="password" placeholder="Enter Secure Password *" class="form-control" type="password" id="password" formControlName="password" />
				</div>
			</div>
			<div *ngIf="formErrors.password" class="alert alert-danger" role="alert">
				{{ formErrors.password }}
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group" id="confirmPasswordGroup">
				<div class="input password required">
					<input name="confirm_password" placeholder="Confirm Password *" class="form-control" id="confirm_password" type="password" formControlName="confirm_password" />
				</div>
			</div>
			<div *ngIf="formErrors.confirm_password" class="alert alert-danger" role="alert">
				{{ formErrors.confirm_password }}
			</div>
		</div>
		<div class="form-actions col-md-12 text-center">
			<div *ngIf="errorMessage" class="alert alert-danger alert-dismissible" role="alert">{{ errorMessage }}</div>
			<button *ngIf="!isSubmitting" type="submit" class="btn btn-primary btn-block" [disabled]="!userRegisterForm.valid">Sign Up</button>
			<button disabled *ngIf="isSubmitting" class="btn btn-primary" href="#" ng-reflect-href="#">Submitting</button>
		</div>
	</div>
</form>
