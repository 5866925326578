import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/_services';
import { TasksService } from 'src/app/_shared/_services/tasks.service';
import { TodoTask } from 'src/app/_shared/_models/tasks-todo.model';

@Component({
	selector: 'app-to-do-list',
	templateUrl: './to-do-list.component.html',
	styleUrls: ['../to-do.scss'],
})
export class ToDoListComponent implements OnInit, OnDestroy {
	@Input() type: string;
	@Input() showCreateNew: boolean;

	// To do item
	todotask: TodoTask = {
		title: '',
		users: [],
	};
	// To-do list
	todoSubscription: Subscription;
	todotasks: TodoTask[] = [];
	todoShow: string;

	constructor(public auth: AuthenticationService, private tasksService: TasksService) {}

	ngOnInit() {
		this.todoShow = 'Pending';
		this.todotask.users.push(this.auth.userDetails.uid);
		this.todoSubscription = this.tasksService.fetchTodoTasks(this.type).subscribe((todoTasks) => {
			this.todotasks = todoTasks;
		});
	}

	addTask() {
		this.tasksService.addTodoTask(this.type, this.todotask).then(() => {
			this.todotask.title = '';
		});
	}

	updateTask(todo, type) {
		todo.status = type === 'Pending' ? 'Completed' : 'Pending';
		this.tasksService.updateTask(todo, todo.id);
	}

	ngOnDestroy() {
		this.todoSubscription.unsubscribe();
	}
}
