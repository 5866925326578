import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/auth/_services';
import moment from 'moment';
import range from 'lodash.range';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class TimeTrackerService {
	constructor(public db: AngularFirestore, private authService: AuthenticationService) { }

	fetchTimes() {
		return this.db.collection('time-tracker', ref => ref.where('active', '==', true).where('submitted', '==', false).where('createdBy', '==', this.authService.userDetails.uid).orderBy('created', 'desc')).valueChanges({ idField: 'id' });
	}

	fetchUserProjects() {
		return this.db
			.collection('projects', (ref) => ref.where('active', '==', true).where('users', 'array-contains', this.authService.userDetails.uid).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	fetchGeneralProjects() {
		return this.db.collection('projects-general', (ref) => ref.where('active', '==', true).orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchProjectTimeCodes(projectId: string) {
		return this.db.collection('time-codes', ref => ref.where('active', '==', true).where('project', '==', projectId)).valueChanges({ idField: 'id' });
	}

	fetchProjectsTimeCodes(projects) {
		const projectIds = projects.map(project => project.id);
		let batches = [];

		while (projectIds.length) {
			const batch = projectIds.splice(0, 10);

			batches.push(this.db.collection('time-codes', ref => ref.where('project', 'in', batch)).valueChanges({ idField: 'id' }));
		}

		return combineLatest<any[]>(batches).pipe(
			map(arr => arr.reduce((acc, cur) => acc.concat(cur))),
		)
	}

	addTime(data) {
		data.active = true;
		data.created = new Date();
		data.createdBy = this.authService.userDetails.uid;
		data.createdByName = `${this.authService.userDetails.firstname} ${this.authService.userDetails.surname}`;
		return this.db.collection('time-tracker').add(data);
	}

	async submitTimeToTimesheet(data) {
		const week = moment(data.day_timestamp.toDate()).isoWeek();
		const day = moment(data.day_timestamp.toDate()).format('dddd').toLowerCase();

		const convertToQuaterDayHours = await this.convertTimeToQuarters(data.dayHours);

		return this.db.collection('timesheets').ref
			.where('active', '==', true)
			.where('year', '==', data.day.year)
			// .where('month', '==', data.day.month)
			.where('week', '==', week)
			.where('project', '==', data.project)
			.where('timecode', '==', data.time_code).get()
			.then((timesheets: any) => {
				if (timesheets.size > 0) {
					// EXISTING TIMESHEET JUST UPDATE DAY
					const timesheetData = timesheets.docs[0].data();
					const dayHours = timesheetData.dayHours;

					Object.keys(dayHours).forEach(key => {
						dayHours[key] = +dayHours[key] + +convertToQuaterDayHours[key];
					});

					this.db.doc(`timesheets/${timesheetData.id}`).set({
						dayHours
					}, { merge: true }).then(() => {
						const promisesArray = [];

						data.ids.forEach(id => {
							const setSubmitted = this.db.doc(`time-tracker/${id}`).update({
								submitted: true
							});

							promisesArray.push(setSubmitted);
						});

						if (data.notes && data.notes.length > 0) {
							data.notes.reverse(); // Write notes in correct chronological order
							data.notes.forEach(note => {
								const notesData = {
									date: data.day_timestamp,
									day,
									note,
									projectId: data.project,
									timecodeId: data.time_code,
									timesheetId: timesheetData.id,
									type: 'timesheet'
								};

								const saveNote = this.generateNote(notesData);
								promisesArray.push(saveNote);
							});
						}

						return Promise.all(promisesArray);
					});
				} else {
					// NOT EXISTING TIMESHEET CREATE NEW ENTRY
					const daysData = this.generateWeeks(convertToQuaterDayHours);

					// GET EXISTING TIMESHEETS FOR YEAR AND WEEK TO GET ORDER NUMBER
					return this.db.collection('timesheets').ref
						.where('active', '==', true)
						.where('createdBy', '==', this.authService.userDetails.uid)
						.where('year', '==', data.day.year)
						.where('week', '==', week)
						.get()
						.then(tsYearWeek => {
							const saveData = {
								active: true,
								created: new Date(),
								createdBy: this.authService.userDetails.uid,
								month: data.day.month,
								order: tsYearWeek.size,
								project: data.project,
								showLabel: true,
								submitted: false,
								timecode: data.time_code,
								week,
								year: data.day.year,
								validated: false,
								...daysData
							};

							return this.db.collection('timesheets').add(saveData).then(ref => {
								const promisesArray = [];

								const setTimesheetId = this.db.doc(`timesheets/${ref.id}`).update({
									id: ref.id
								});

								promisesArray.push(setTimesheetId);

								data.ids.forEach(id => {
									const setSubmitted = this.db.doc(`time-tracker/${id}`).update({
										submitted: true
									});

									promisesArray.push(setSubmitted);
								});

								if (data.notes && data.notes.length > 0) {
									data.notes.reverse(); // Write notes in correct chronological order
									data.notes.forEach(note => {
										const notesData = {
											date: data.day_timestamp,
											day,
											note,
											projectId: data.project,
											timecodeId: data.time_code,
											timesheetId: ref,
											type: 'timesheet'
										};

										const saveNote = this.generateNote(notesData);
										promisesArray.push(saveNote);
									});
								}

								return Promise.all(promisesArray);
							});
						});
				}
			});
	}

	private generateWeeks(updatedDayHours) {
		const dates = this.fillDates(moment(new Date(), 'DD-MM-YYYY'));
		let weeks = [];

		while (dates.length > 0) {
			weeks = dates.splice(0, 7);
		}

		const dayDates = {
			monday: weeks[0]._d,
			tuesday: weeks[1]._d,
			wednesday: weeks[2]._d,
			thursday: weeks[3]._d,
			friday: weeks[4]._d,
			saturday: weeks[5]._d,
			sunday: weeks[6]._d,
		};

		let dayHours = {
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
			saturday: 0,
			sunday: 0,
		};

		if (updatedDayHours) {
			dayHours = updatedDayHours;
		}

		return {
			dayDates,
			dayHours,
		};
	}

	private fillDates(currentMoment: moment.Moment) {
		const firstDayOfGrid = moment(currentMoment).startOf('isoWeek');
		const lastDayOfGrid = moment(currentMoment).endOf('isoWeek').add(1, 'days');

		const startCalendar = firstDayOfGrid.date();

		return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
			return moment(firstDayOfGrid).date(date);
		});
	}

	generateCombinedData(times) {
		const combineIds = {};
		const combineTimes = {};
		const combineDayHours = {};
		const combineNotes = {};
		const combineData = {};

		times.forEach((time) => {
			const week = moment(time.day_timestamp.toDate()).isoWeek();
			const day = moment(time.day_timestamp.toDate()).format('dddd').toLowerCase();
			const dayKey = `${time.project}-${time.time_code}-${time.day.year}-${time.day.month}-${time.day.day}`;
			const weekKey = `${time.project}-${time.time_code}-${time.day.year}-${week}`;

			combineIds[weekKey] = (combineIds[weekKey]) ? [...combineIds[weekKey], time.id] : [time.id];
			combineTimes[dayKey] = (combineTimes[dayKey]) ? +combineTimes[dayKey] + +time.timer : time.timer;
			if (time.description && time.description !== '') {
				combineNotes[dayKey] = (combineNotes[dayKey]) ? [...combineNotes[dayKey], time.description] : [time.description];
			}
			combineDayHours[weekKey] = (combineDayHours[weekKey]) ? combineDayHours[weekKey] : {
				monday: 0,
				tuesday: 0,
				wednesday: 0,
				thursday: 0,
				friday: 0,
				saturday: 0,
				sunday: 0
			};
			combineDayHours[weekKey][day] = combineTimes[dayKey];
			combineData[weekKey] = {
				ids: combineIds[weekKey],
				day: time.day,
				day_timestamp: time.day_timestamp,
				dayHours: combineDayHours[weekKey],
				notes: combineNotes[dayKey],
				project: time.project,
				time_code: time.time_code,
			};
		});

		return Promise.resolve(combineData);
	}

	private convertTimeToQuarters(dayHours) {
		Object.keys(dayHours).forEach(key => {
			// If timer less than 1 min then set it to 1 min else Math.round sets value to zero
			dayHours[key] = (dayHours[key] && dayHours[key] > 0 && dayHours[key] < 60) ? 60 : dayHours[key];
			const timeToAllocate = (dayHours[key]) ? Math.round(((dayHours[key] / 60) / 60) * 100) / 100 : 0;
			const timeToAllocateString = timeToAllocate.toFixed(2).toString();
			const timeToAllocateBefore = +timeToAllocateString.split('.')[0];
			const timeToAllocateAfter = +timeToAllocateString.split('.')[1];

			const convertToQuater = {
				before: timeToAllocateBefore,
				after: 0
			};

			if (timeToAllocateAfter > 0 && timeToAllocateAfter <= 25) {
				convertToQuater.after = 25;
			}
			if (timeToAllocateAfter > 25 && timeToAllocateAfter <= 50) {
				convertToQuater.after = 50;
			}
			if (timeToAllocateAfter > 50 && timeToAllocateAfter <= 75) {
				convertToQuater.after = 75;
			}
			if (timeToAllocateAfter > 75) {
				convertToQuater.before = +timeToAllocateAfter + 1;
				convertToQuater.after = 0;
			}

			dayHours[key] = +`${convertToQuater.before}.${convertToQuater.after}`;
		});

		return dayHours;
	}

	generateNote(data) {
		data.active = true;
		data.created = new Date();
		data.createdBy = this.authService.userDetails.uid;
		data.createdByName = `${this.authService.userDetails.firstname} ${this.authService.userDetails.surname}`;
		return this.db.collection('notes').add(data);
	}
}
