<div class="quick-panel-overlay" [ngClass]="{ hidden: sideNavService.hideQuickPanel }">
	<div class="quick-panel">
		<button type="button" class="btn btn-danger close-panel" (click)="sideNavService.toggleQuickPanel()"><span class="fa fa-close"></span></button>
		<div class="quick-components">
			<div class="quick-component">
				<div class="row">
					<div class="col-md-12">
						<div class="quick-tabs">
							<ul class="nav nav-tabs" role="tablist">
								<li class="nav-item" (click)="tabClick('To-do')">
									<a class="nav-link" data-toggle="tab" href="#toDo" [ngClass]="{ active: sideNavService.selectedQuickPanelTab === 'To-do' ? true : false }">
										To-Do List
									</a>
								</li>
								<li class="nav-item" (click)="tabClick('notifications')">
									<a
										class="nav-link"
										data-toggle="tab"
										href="#notifications"
										[ngClass]="{ active: sideNavService.selectedQuickPanelTab === 'notifications' ? true : false }"
									>
										Notifications
									</a>
								</li>
								<li class="nav-item" (click)="tabClick('timeTracker')">
									<a class="nav-link" data-toggle="tab" href="#timeTracker"
										[ngClass]="{ active: sideNavService.selectedQuickPanelTab === 'timeTracker' ? true : false }">
										My Time
									</a>
								</li>
							</ul>

							<!-- Tab panes -->
							<div class="tab-content">
								<div id="toDo" class="tab-pane" [ngClass]="{ active: sideNavService.selectedQuickPanelTab === 'To-do' ? true : false }">
									<app-to-do-list [type]="'To-do'" [showCreateNew]="true"></app-to-do-list>
								</div>
								<div id="notifications" class="tab-pane" [ngClass]="{ active: sideNavService.selectedQuickPanelTab === 'notifications' ? true : false }">
									<br />
									<app-to-do-list [type]="'notifications'" [showCreateNew]="false"></app-to-do-list>
								</div>
								<div id="timeTracker" class="tab-pane" [ngClass]="{ active: sideNavService.selectedQuickPanelTab === 'timeTracker' ? true : false }">
									<app-time-tracker></app-time-tracker>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
