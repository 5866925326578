import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'src/app/_shared/_models/user.model';
import { of, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthenticationService {
	user: Observable<User>;
	userDetails: User;

	constructor(
		public afs: AngularFirestore, // Inject Firestore service
		public afAuth: AngularFireAuth, // Inject Firebase auth service
		public router: Router,
		private permissionsService: NgxPermissionsService
	) {
		this.user = this.afAuth.authState.pipe(
			switchMap((user) => {
				if (user) {
					let updateUser = false;

					if (!this.userDetails) {
						updateUser = true;
					} else if (this.userDetails.email !== user.email) {
						updateUser = true;
					} else {
						this.permissionsManager();
					}

					if (updateUser) {
						const userDoc = this.afs.collection('users').doc<User>(user.uid).ref;
						userDoc.get().then((doc) => {
							if (doc.exists) {
								this.userDetails = doc.data();
								this.permissionsManager();
							}
						});
					}
					return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
				} else {
					return of(null);
				}
			})
		);
	}

	permissionsManager() {
		if (this.userDetails.hasOwnProperty('permissions')) {
			this.permissionsService.loadPermissions(this.userDetails.permissions);
			localStorage.setItem('permissions', JSON.stringify(this.userDetails.permissions));
			return Promise.resolve();
		} else {
			this.permissionsService.loadPermissions(['general']);
			localStorage.setItem('permissions', JSON.stringify(['general']));
			return Promise.resolve();
		}
	}

	emailSignUp(email, password, firstname, surname) {
		return this.afAuth.createUserWithEmailAndPassword(email, password).then((user: any) => {
			const userData = {
				firstname,
				surname,
				email: email.toLowerCase(),
				uid: user.user.uid,
			};
			return this.setUserData(userData);
		});
	}

	emailLogin(email, password) {
		return this.afAuth.signInWithEmailAndPassword(email, password);
	}

	sendEmailVerificationRequest(user) {
		return this.afs.collection(`pending`).doc(user.firebaseId).set({
			request: 'emailVerificationRequests',
			email: user.email.toLowerCase(),
			firstname: user.firstname,
			uid: user.firebaseId,
		});
	}

	sendEmailWelcomeMail(firebaseId) {
		return this.afs.collection(`pending`).add({
			request: 'emailWelcomeMail',
			firebaseId,
		});
	}

	verifyUserEmail(firebaseId: string) {
		// CHECK USER UID EXISTS
		const userRef = this.afs.collection('public/registeredUsers/list').doc(firebaseId).ref;

		return userRef
			.get()
			.then((snap) => {
				if (snap.exists === true) {
					return this.afs
						.collection(`pending`)
						.doc(firebaseId)
						.set(
							{
								request: 'verifyUser',
								verified: true,
							},
							{ merge: true }
						)
						.then(() => {
							this.sendEmailWelcomeMail(firebaseId);
							return Promise.resolve('User Verified');
						});
				} else {
					return Promise.reject('User could not be verified');
				}
			})
			.catch((error) => {
				return Promise.reject('User could not be verified');
			});
	}

	// PASSWORD RESET/UPDATE
	resetPassword(email) {
		const fbAuth = this.afAuth;
		return fbAuth.sendPasswordResetEmail(email.toLowerCase());
	}

	updateUserPassword(user, password) {
		return user
			.updatePassword(password)
			.then(() => {
				const success = {
					message: 'password successful',
					type: 'success',
				};
				return success;
			})
			.catch((error) => {
				const err = {
					error,
					type: 'error',
				};
				return err;
			});
	}

	// EMAIL UPDATE
	updateUserEmail(userEmail) {
		return this.afAuth.currentUser.then((user) => user.updateEmail(userEmail));
	}

	reAuthUser(email, password) {
		const credential = auth.EmailAuthProvider.credential(email, password);
		return this.afAuth.currentUser.then((user) => user.reauthenticateWithCredential(credential));
	}

	// Sign out
	signOut() {
		return this.afAuth.signOut().then(() => {
			this.router.navigate(['login']);
		});
	}

	// UPDATE PASSWORD
	changePassword(email, currentPassword) {
		return this.reAuthUser(email, currentPassword)
			.then((user) => {
				return user;
			})
			.catch((error: any) => {
				return error;
			});
	}

	/* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
	private setUserData(user) {
		console.log(user);
		const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
		return userRef.set(user, {
			merge: true,
		});
	}
}
