<form [formGroup]="passwordResetForm" (ngSubmit)="changePassword()">
	<div class="row">
		<div class="col-md-12 text-center mb-2">
			<h2>Reset Password</h2>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<div class="input email">
					<input type="email" id="email" placeholder="Email" class="input form-control margin-bottom-15" formControlName="email" required />
				</div>
			</div>
			<div *ngIf="formErrors.email" class="alert alert-danger" role="alert">
				{{ formErrors.email }}
			</div>
		</div>
		<div class="col-md-12">
			<button type="submit" class="btn btn-primary btn-block" [disabled]="!passwordResetForm.valid">Reset</button>
		</div>
		<div *ngIf="message.message != ''">
			<div class="col-md-12">
				<span [ngClass]="message.type === 'success' ? 'm--font-success' : 'm--font-danger'">
					{{ message.message }}
				</span>
			</div>
		</div>
	</div>
</form>
