import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TimesheetsService } from 'src/app/theme/pages/timesheets/_services/timesheets.service';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
	selector: 'app-timesheets-user-table',
	templateUrl: './timesheets-user-table.component.html',
	styleUrls: ['../shared-timesheets.scss'],
})
export class TimesheetsUserTableComponent implements OnInit, OnDestroy {
	@Input() weekNo: any;
	@Output() showTable = new EventEmitter<boolean>();

	isLoading = false;
	timesheetsSubsription: Subscription;
	timesheets: any[] = [];
	currentDate: any;

	// TABLE
	@ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
	dtOptions: any = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized = false;

	constructor(private router: Router, private timesheetsService: TimesheetsService) { }

	ngOnInit() {
		this.isLoading = true;
		this.currentDate = moment().format('DD-MM-YYYY');
		this.dtOptions = {
			processing: true,
			destroy: true,
			responsive: true,
		};
		this.timesheetsSubsription = this.timesheetsService.getAllUsersTimesheets().subscribe((timesheets: any) => {
			this.timesheets = timesheets.filter((sheet) => sheet.week !== this.weekNo);
			this.isLoading = false;
			setTimeout(() => {
				this.rerender();
			});
		});
	}

	rerender(): void {
		if (this.isDtInitialized) {
			this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
				this.dtTrigger.next();
			});
		} else {
			this.isDtInitialized = true;
			this.dtTrigger.next();
		}
	}

	navigateWeek(timesheet) {
		this.showTable.emit(false);
		const date = moment(timesheet.dayDates.monday.seconds * 1000).format(`DD-MM-YYYY`);
		this.router.navigate([`/timesheets/edit/${timesheet.year}/${timesheet.week}/${date}/auth`]);
	}

	ngOnDestroy() {
		this.timesheetsSubsription.unsubscribe();
		this.dtTrigger.unsubscribe();
	}
}
