import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { AsideNavTogglerComponent } from './aside-nav-toggler/aside-nav-toggler.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { FooterComponent } from './footer/footer.component';
import { CoreModule } from 'src/app/_shared/core.module';
import { QuickPanelComponent } from './quick-panel/quick-panel.component';

@NgModule({
	declarations: [AsideNavComponent, AsideNavTogglerComponent, TopNavComponent, FooterComponent, QuickPanelComponent],
	exports: [AsideNavComponent, AsideNavTogglerComponent, TopNavComponent, FooterComponent, QuickPanelComponent],
	imports: [CommonModule, RouterModule, NgxPermissionsModule.forChild(), CoreModule],
})
export class LayoutModule {}
