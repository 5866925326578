import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../_services';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['../../auth.component.scss', './reset-password.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
	constructor(public auth: AuthenticationService, private router: Router, private route: ActivatedRoute) {}

	ngOnInit() {}
}
