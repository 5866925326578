import { Component } from '@angular/core';

@Component({
	selector: 'app-verification-page',
	templateUrl: './verification-page.component.html',
	styleUrls: ['../../auth.component.scss', './verification-page.component.scss'],
})
export class VerificationPageComponent {
	constructor() {}
}
