import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { defaultColours } from '../_configs/colors.config';
import { StorageService } from '../_shared/_services/storage.service';
import { ThemeService } from '../_shared/_services/theme.service';

@Injectable({
	providedIn: 'root',
})
export class ThemeResolverService implements Resolve<any> {
	constructor(private themeService: ThemeService, private storageService: StorageService) {}
	async resolve(route: ActivatedRouteSnapshot) {
		this.themeService.getThemeSettings().subscribe((themeSettings: any) => {
			this.storageService.themeSettings.next(themeSettings);
			const { themeColours } = themeSettings;
			const colourSettings = {
				...defaultColours,
				...themeColours,
			};
			const { primary, secondary, dark, light } = colourSettings;
			const themeColors = {
				...colourSettings,
				'primary-rgb': primary,
				'secondary-rgb': secondary,
				'dark-rgb': dark,
				'light-rgb': light,
			};
			this.setTheme(themeColors);
		});
	}

	private setTheme(theme: {}) {
		Object.keys(theme).forEach((key) => {
			if (!key.includes('rgb')) {
				document.documentElement.style.setProperty(`--${key}`, theme[key]);
			} else {
				const setRGB = this.hexToRgb(theme[key]);
				document.documentElement.style.setProperty(`--${key}`, `${setRGB.r},${setRGB.g},${setRGB.b}`);
			}
		});
	}

	hexToRgb(hex) {
		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}
}
