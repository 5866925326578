import { Component, OnInit } from '@angular/core';
import { AsideNavService } from '../_services/authorised-side-nav.service';

@Component({
	selector: 'app-quick-panel',
	templateUrl: './quick-panel.component.html',
	styleUrls: ['./quick-panel.component.scss'],
})
export class QuickPanelComponent implements OnInit {
	constructor(public sideNavService: AsideNavService) {}

	ngOnInit() {}

	tabClick(tab: string) {
		this.sideNavService.selectedQuickPanelTab = tab;
	}

	outsideClick() {
		this.sideNavService.hideQuickPanel = true;
	}
}
