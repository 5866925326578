import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { VerificationPageComponent } from './pages/verification-page/verification-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password/reset-password.component';
import { AuthGuard } from './_guards';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{ path: 'login', component: LoginPageComponent },
			{ path: 'login/:uid', component: LoginPageComponent },
			{ path: 'verification/:uid', component: VerificationPageComponent },
			{ path: 'reset-password/:uid', canActivate: [AuthGuard], component: ResetPasswordPageComponent },
			{ path: 'register/:uid', component: RegisterPageComponent },
			{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
