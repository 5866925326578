import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services';

@Component({
	selector: 'app-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['../../auth.component.scss', './login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
	showHide: boolean = false;
	passReset: boolean = false;

	constructor(public auth: AuthenticationService) {}

	ngOnInit() {}

	toggleForms() {
		this.showHide = !this.showHide;
	}

	togglePassReset() {
		this.passReset = !this.passReset;
	}
}
