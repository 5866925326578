import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../auth/_services';
@Injectable({
	providedIn: 'root',
})
export class PermissionsResolverService implements Resolve<any> {
	constructor(private permissionsService: NgxPermissionsService, private authService: AuthenticationService) {}
	resolve(route: ActivatedRouteSnapshot) {
		return this.authService.user
			.pipe(take(1))
			.toPromise()
			.then((usertmp) => {
				const user = this.authService.userDetails ? this.authService.userDetails : null;
				if (user) {
					if (user.permissions) {
						this.permissionsService.loadPermissions(user.permissions);
						localStorage.setItem('permissions', JSON.stringify(user['permissions']));
						return Promise.resolve();
					} else {
						this.permissionsService.loadPermissions(['general']);
						localStorage.setItem('permissions', JSON.stringify(['general']));
						return Promise.resolve();
					}
				} else {
					return Promise.resolve();
				}
			});
	}
}
