import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit() {
		this.router.events.subscribe((route) => {
			window.scrollTo(0, 0);
			if (route instanceof NavigationStart) {
			}
			if (route instanceof NavigationEnd) {
			}
		});
	}
}
