import { Component, OnInit } from '@angular/core';
import { AsideNavService } from '../_services/authorised-side-nav.service';

@Component({
	selector: 'app-aside-nav-toggler',
	templateUrl: './aside-nav-toggler.component.html',
	styleUrls: ['./aside-nav-toggler.component.scss'],
})
export class AsideNavTogglerComponent implements OnInit {
	constructor(public sideNavService: AsideNavService) {}

	ngOnInit() {}
}
