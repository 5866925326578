import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth/_guards';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './_shared/core.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ThemeModule } from './theme/theme.module';
import { RouterModule } from '@angular/router';
import { PermissionsResolverService } from './_resolvers/permissions.resolver';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule.enablePersistence(),
		AngularFireAuthModule,
		AngularFireStorageModule,
		AngularFireFunctionsModule,
		BrowserAnimationsModule,
		ThemeModule,
		CoreModule.forRoot(),
		NgxPermissionsModule.forRoot(),
		RouterModule,
	],
	providers: [AuthGuard, { provide: REGION, useValue: 'us-central1' }],
	bootstrap: [AppComponent],
})
export class AppModule {}
