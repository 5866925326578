import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToDoListComponent } from './to-do-list/to-do-list.component';
import { ToDoProjectListComponent } from './to-do-project-list/to-do-project-list.component';

@NgModule({
	declarations: [ToDoListComponent, ToDoProjectListComponent],
	imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule],
	exports: [ToDoListComponent, ToDoProjectListComponent],
})
export class ToDoModule {}
