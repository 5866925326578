import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'src/app/auth/_services';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	constructor(public db: AngularFirestore, private authService: AuthenticationService) {}

	getThemeSettings() {
		return this.db.doc(`settings/theme`).valueChanges();
	}

	saveThemeSettings(settings) {
		return this.db.doc(`settings/theme`).set(settings, { merge: true });
	}

	fetchThemeTemplates() {
		return this.db.collection('settings/theme/templates', (ref) => ref.where('active', '==', true).orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	addThemeTemplate(settings) {
		const data = {
			active: true,
			created: new Date(),
			createdBy: this.authService.userDetails.uid,
			createdByName: `${this.authService.userDetails.firstname} ${this.authService.userDetails.surname}`,
			...settings
		};
		return this.db.collection(`settings/theme/templates`).add(data);
	}
}
