import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedTimesheetsModule } from './timesheets/shared-timesheets.module';
import { ToDoModule } from './to-do/to-do.module';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { ModalModule } from './modal';
import { NotesModule } from './notes';
import { TimeTrackerModule } from './time-tracker';
import { ChipsMultiSelectModule } from './chips-multi-select/chips-multi-select.module';
import { SharedTimeCodesModule } from './timecodes/shared-timecodes.module';

@NgModule({
	declarations: [AuditLogComponent],
	imports: [CommonModule, SharedTimesheetsModule, ToDoModule, ModalModule, NotesModule, TimeTrackerModule, ChipsMultiSelectModule],
	exports: [SharedTimesheetsModule, SharedTimeCodesModule, ToDoModule, AuditLogComponent, ModalModule, NotesModule, TimeTrackerModule, ChipsMultiSelectModule],
})
export class SharedComponentsModule {}
