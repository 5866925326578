<form [formGroup]="timeCodesForm" (submit)="addTimeCode()">
	<div class="row pt-3">
		<div class="col-md-12">
			<div class="form-group has-float-label">
				<input type="text" placeholder="Enter Code *" id="code" class="form-control" formControlName="code" required />
				<label for="code">Code</label>
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group has-float-label">
				<input type="text" placeholder="Enter Description *" id="description" class="form-control" formControlName="description" required />
				<label for="description">Description</label>
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<div class="custom-control custom-checkbox">
					<input type="checkbox" title="Notes Required" class="custom-control-input" formControlName="noteRequired" id="noteRequired" name="noteRequired" />
					<label title="Notes Required" class="custom-control-label" for="noteRequired">Notes Required</label>
				</div>
			</div>
		</div>
	</div>
	<div class="row pt-3">
		<div class="col-md-12 text-right">
			<button type="reset" class="btn btn-light btn-sm mr-2">Clear</button>
			<button [disabled]="submitting || timeCodesForm.invalid" type="submit" class="btn btn-outline-success btn-sm">
				<i class="fa fa-plus"></i>
				<span *ngIf="!submitting">Create</span>
				<span *ngIf="submitting">Creating</span>
			</button>
		</div>
	</div>
</form>
