import { Component, OnInit, Input } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';

type UserFields = 'email';
type FormErrors = { [u in UserFields]: string };

@Component({
	selector: 'app-password-reset',
	templateUrl: './auth-password-reset.component.html',
})
export class AuthPasswordResetComponent implements OnInit {
	@Input() userInfo: any;

	passwordResetForm: FormGroup;
	newUser = false; // to toggle login or signup form
	passReset = false; // set to true when password reset is triggered
	message = { message: '', type: '' };

	formErrors: FormErrors = {
		email: '',
	};
	validationMessages = {
		email: {
			required: 'Email is required.',
			email: 'Email must be a valid email',
		},
	};

	constructor(private fb: FormBuilder, private auth: AuthenticationService) {}

	ngOnInit() {
		this.buildForm();
	}

	changePassword() {
		this.auth
			.resetPassword(this.passwordResetForm.value['email'])
			.then(() => {
				this.message.message = 'Reset password has been sent to your email address';
				this.message.type = 'success';
			})
			.catch(() => {
				this.message.message = 'Reset password could not be sent to your email address, try again';
				this.message.type = 'error';
			});
	}

	buildForm() {
		this.passwordResetForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
		});

		this.passwordResetForm.valueChanges.subscribe((data) => this.onValueChanged(data));
		this.onValueChanged();
	}

	reset() {
		this.buildForm();
	}

	// Updates validation state on form changes.
	onValueChanged(data?: any) {
		if (!this.passwordResetForm) {
			return;
		}
		const form = this.passwordResetForm;
		for (const field in this.formErrors) {
			if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && field === 'email') {
				// clear previous error message (if any)
				this.formErrors[field] = '';
				const control = form.get(field);
				if (control && control.dirty && !control.valid) {
					const messages = this.validationMessages[field];
					if (control.errors) {
						for (const key in control.errors) {
							if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
								this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
							}
						}
					}
				}
			}
		}
	}
}
