import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { TimesheetsUserTableComponent } from './timesheets-user-table/timesheets-user-table.component';
import { TimesheetsApprovalsTableComponent } from './timesheets-approvals-table/timesheets-approvals-table.component';

@NgModule({
	declarations: [TimesheetsUserTableComponent, TimesheetsApprovalsTableComponent],
	imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, DataTablesModule],
	exports: [TimesheetsUserTableComponent, TimesheetsApprovalsTableComponent],
})
export class SharedTimesheetsModule {}
