import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services';

type UserFields = 'email' | 'password' | 'firstname' | 'surname' | 'confirm_password';
type FormErrors = { [u in UserFields]: string };

@Component({
	selector: 'app-register',
	templateUrl: './auth-register.component.html',
})
export class AuthRegisterComponent implements OnInit {
	userRegisterForm: FormGroup;
	errorMessage: any;
	errorCode: any;
	isSubmitting: boolean = false;
	isLoading: boolean = true;

	formErrors: FormErrors = {
		firstname: '',
		surname: '',
		email: '',
		password: '',
		confirm_password: '',
	};
	validationMessages = {
		firstname: {
			required: 'Please enter your Firstname.',
		},
		surname: {
			required: 'Please enter your Firstname.',
		},
		email: {
			required: 'Email is required.',
			email: 'Email must be a valid email',
		},
		password: {
			required: 'Password is required.',
			pattern: 'Password must include atleast one letter and one number.',
			minlength: 'Password must be at least 6 characters long.',
			maxlength: 'Password cannot be more than 25 characters long.',
		},
		confirm_password: {
			required: 'Confirm password is required',
			passwordMismatch: 'Passwords do not match',
		},
		checkbox: {
			required: 'Please tick to indicate you have read and agreed with the terms and conditions.',
		},
	};

	constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router) {}

	ngOnInit() {
		this.buildForm();
	}

	signup() {
		this.isSubmitting = true;
		this.errorMessage = undefined;
		this.auth
			.emailSignUp(
				this.userRegisterForm.value['email'],
				this.userRegisterForm.value['password'],
				this.userRegisterForm.value['firstname'],
				this.userRegisterForm.value['surname']
			)
			.then((result) => {
				this.isSubmitting = false;
				this.router.navigate(['/dashboard']);
			})
			.catch((error) => {
				if (error) {
					console.log(error);
					this.isSubmitting = false;
					this.errorMessage = error.message;
				}
			});
	}

	buildForm() {
		this.userRegisterForm = this.fb.group({
			firstname: ['', [Validators.required]],
			surname: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+).*$'), Validators.minLength(6), Validators.maxLength(25)]],
			confirm_password: ['', [Validators.required, this.isEqualTo('password')]],
		});

		this.userRegisterForm.valueChanges.subscribe((data) => this.onValueChanged(data));
		this.onValueChanged();
	}

	isEqualTo(field): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (control.root.value[field] === control.value) {
				return null;
			} else {
				return { passwordMismatch: { value: 'passwordMismatch' } };
				//return null;
			}
		};
	}

	reset() {
		this.buildForm();
	}

	// Updates validation state on form changes.
	onValueChanged(data?: any) {
		if (!this.userRegisterForm) {
			return;
		}
		const form = this.userRegisterForm;
		for (const field in this.formErrors) {
			if (
				Object.prototype.hasOwnProperty.call(this.formErrors, field) &&
				(field === 'email' || field === 'password' || field === 'confirm_password' || field === 'firstname' || field === 'surname')
			) {
				// clear previous error message (if any)
				this.formErrors[field] = '';
				const control = form.get(field);
				if (control && control.dirty && !control.valid) {
					const messages = this.validationMessages[field];
					if (control.errors) {
						for (const key in control.errors) {
							if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
								this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
							}
						}
					}
				}
			}
		}
	}
}
