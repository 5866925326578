import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NotesComponent } from './notes.component';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	declarations: [NotesComponent],
	exports: [NotesComponent],
})
export class NotesModule {}
