<div *ngIf="!isLoading; else loading">
	<form [formGroup]="userLoginForm" (ngSubmit)="login(userLoginForm.get('email').value, userLoginForm.get('password').value)">
		<div class="row">
			<div class="col-md-12 text-center mb-2">
				<h2>Sign In</h2>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<div class="input email">
						<input type="email" id="email" placeholder="Email Address *" class="form-control" formControlName="email" required />
					</div>
				</div>
				<div *ngIf="formErrors.email" class="alert alert-danger" role="alert">
					{{ formErrors.email }}
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<div class="input password">
						<input type="password" placeholder="Password *" id="password" class="form-control" formControlName="password" required />
					</div>
				</div>
				<div *ngIf="formErrors.password" class="alert alert-danger" role="alert">
					{{ formErrors.password }}
				</div>
			</div>
			<div class="col-md-12">
				<button type="submit" class="btn btn-primary btn-block" [disabled]="!userLoginForm.valid">Login</button>
			</div>
		</div>
	</form>
</div>
<ng-template #loading>
	<div class="text-center">
		<div class="m-loader m-loader--brand" style="width: 30px; display: inline-block"></div>
		<div>
			Verifying Details
			<div class="spinner-border spinner-border-sm ml-2 mb-1" role="status"></div>
		</div>
	</div>
</ng-template>
