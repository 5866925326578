import { Component, OnInit } from '@angular/core';
import { AsideNavService } from './layouts/_services/authorised-side-nav.service';
import { Subscription } from 'rxjs';
import { StorageService } from '../_shared/_services/storage.service';
import { AuthenticationService } from '../auth/_services';
import { Router } from '@angular/router';
import { User } from '../_shared/_models/user.model';

@Component({
	selector: 'app-theme',
	templateUrl: './theme.component.html',
})
export class ThemeComponent implements OnInit {
	loadingData: boolean = false;
	innerWidth: any;

	// USER
	user: User;
	userSubscription: Subscription;

	constructor(public auth: AuthenticationService, public sideNavService: AsideNavService) {}

	ngOnInit() {
		this.loadingData = true;
		this.userSubscription = this.auth.user.subscribe((userDetails) => {
			if (userDetails) {
				this.user = userDetails;
				if (!this.user.emailVerified) {
					// this.router.navigate(['/profile'])
				}
				this.auth.userDetails = userDetails;
				this.loadingData = false;
			} else {
				this.loadingData = false;
			}
		});
	}
}
