import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { User } from '../_models/user.model';

@Injectable({
	providedIn: 'root', // <--provides this service in the root ModuleInjector
})
export class StorageService {
	// USER DETAILS
	public userDetails = new BehaviorSubject<User>({});

	// USER RESET DETAILS
	public userResetDetails = new BehaviorSubject<any>({});

	// THEME SETTINGS
	public themeSettings = new BehaviorSubject<any>({});

	// MODALS
	public modal = new BehaviorSubject<any>({});
	currentModal = this.modal.asObservable();

	constructor() {}
}
