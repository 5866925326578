import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import moment from 'moment';
import { TimeTrackerService } from './time-tracker.service';

@Component({
	selector: 'app-time-tracker',
	templateUrl: './time-tracker.component.html',
	styleUrls: ['./time-tracker.component.scss'],
})
export class TimeTrackerComponent implements OnInit, OnDestroy {
	times: any = [];
	timesForm = new FormGroup({
		description: new FormControl('', Validators.required),
		project: new FormControl(''),
		time_code: new FormControl(''),
		tags: new FormControl([]),
		billable: new FormControl(true),
		start: new FormControl(''),
		end: new FormControl(''),
		day: new FormControl('', Validators.required),
		timer: new FormControl('00:00:00', Validators.required),
	});
	submitting = false;
	subscriptions = new Subscription();
	counter = 0;
	counterSub = new Subscription();
	counterStarted = false;
	userProjects = [];
	timeCodes = [];
	projectTimeCodes = [];
	projectsList = [];
	timeCodesList = [];
	showSubmit = false;
	submittingAll = false;
	submittingIndividual = '';

	constructor(private timeTrackerService: TimeTrackerService) { }

	ngOnInit() {
		this.subscriptions.add(
			this.timeTrackerService.fetchTimes().subscribe((times) => {
				this.times = times;
				this.setDefaults();
				const storageTimer = JSON.parse(localStorage.getItem('time-tracker'));
				// CHECK IF STORAGE DAY VALUE IS NOT SET
				if (!storageTimer.day) {
					const todayDate = new Date();
					storageTimer.day = { year: todayDate.getFullYear(), month: todayDate.getMonth() + 1, day: todayDate.getDate() };
				}
				// CHECK IF STORAGE DAY VALUE IS SET TO EMPTY OBJECT
				if (storageTimer.day && storageTimer.day === '') {
					const todayDate = new Date();
					storageTimer.day = { year: todayDate.getFullYear(), month: todayDate.getMonth() + 1, day: todayDate.getDate() };
				}
				this.counter = (storageTimer.seconds) ? storageTimer.seconds : 0;
				this.timesForm.patchValue(storageTimer);
			})
		);

		this.subscriptions.add(
			this.timeTrackerService.fetchUserProjects().subscribe((userProjects: any) => {
				this.timeTrackerService.fetchGeneralProjects().subscribe((generalProjects: any) => {
					this.userProjects = [
						...userProjects.map((project) => {
							this.projectsList[project.id] = project.name;
							return {
								id: project.id,
								name: project.name,
							};
						}),
						...generalProjects.map((project) => {
							this.projectsList[project.id] = project.name;
							return {
								id: project.id,
								name: project.name,
							};
						}),
					];
					this.timeTrackerService.fetchProjectsTimeCodes(this.userProjects).subscribe(timeCodes => {
						this.timeCodes = timeCodes.map((timeCode: any) => {
							this.timeCodesList[timeCode.id] = `[${timeCode.code}] ${timeCode.description}`;
							return timeCode;
						}).filter(item => {
							return item.active;
						});

						// IF PROJECT SELECTED AFTER PAGE REFRESH THEN FILTER PROJECT TIME CODES FOR DROPDOWN
						if (this.timesForm.value.project !== '') {
							this.filterProjectTimeCode();
						}
					});
				});
			})
		);
	}

	setDefaults() {
		const todayDate = new Date();
		this.timesForm.reset();
		this.timesForm.patchValue({
			project: '',
			time_code: '',
			day: { year: todayDate.getFullYear(), month: todayDate.getMonth() + 1, day: todayDate.getDate() },
			timer: '00:00:00',
			billable: true
		});
		this.counter = 0;
		this.counterStarted = false;
	}

	startTimer() {
		this.counterStarted = true;
		if (this.counter === 0) {
			this.timesForm.patchValue({ start: moment().format('HH:mm'), end: '' });
		}
		this.counterSub = interval(1000).subscribe(() => {
			this.counter = this.counter + 1;
			this.timesForm.patchValue({ timer: this.formatTime(this.counter) });
			localStorage.setItem('time-tracker', JSON.stringify({
				...this.timesForm.value,
				seconds: this.counter
			}));
		});
	}

	stopTimer() {
		this.counterStarted = false;
		const startSeconds = this.formatToSeconds(`${this.timesForm.value.start}:00`);
		const duration = startSeconds + this.counter;
		this.timesForm.patchValue({ end: this.formatTime(duration).substr(0, 5) });
		this.counterSub.unsubscribe();
	}

	addTime() {
		this.submitting = true;
		const data = this.timesForm.value;
		data.timer = this.formatToSeconds(this.timesForm.value.timer);
		data.day_timestamp = new Date(`${this.timesForm.value.day.year}-${this.timesForm.value.day.month}-${this.timesForm.value.day.day}`);
		data.submitted = false;
		this.timeTrackerService.addTime(data).then(() => {
			this.submitting = false;
			localStorage.setItem('time-tracker', JSON.stringify({
				description: '',
				project: '',
				time_code: '',
				tags: [],
				billable: true,
				start: '',
				end: '',
				day: '',
				timer: this.formatTime(0),
				seconds: 0,
			}));
			this.setDefaults();
			this.projectTimeCodes = [];
		});
	}

	updateTimer() {
		this.counter = this.formatToSeconds(this.timesForm.value.timer);
		this.stopTimer();
	}

	updateLocalStorage() {
		localStorage.setItem('time-tracker', JSON.stringify({
			...this.timesForm.value,
			seconds: this.counter
		}));
	}

	formatTime(seconds) {
		const duration = moment.duration(seconds, 'seconds');
		const result = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
		return result;
	}

	formatToSeconds(HHmmss) {
		const results = HHmmss.split(':').reduce((acc, time) => (60 * acc) + +time);
		return results;
	}

	filterProjectTimeCode() {
		this.projectTimeCodes = this.timeCodes.filter(item => item.project === this.timesForm.value.project);
	}

	submitShowHide() {
		this.showSubmit = !this.showSubmit;
	}

	async submitRow(index) {
		const combineData = await this.timeTrackerService.generateCombinedData([this.times[index]]);
		this.submittingIndividual = this.times[index].id;

		Object.values(combineData).forEach((time) => {
			this.timeTrackerService.submitTimeToTimesheet(time).then(() => {
				this.submittingIndividual = '';
			});
		});
	}

	async submitAllRows() {
		this.submittingAll = true;

		const combineData = await this.timeTrackerService.generateCombinedData(this.times);

		Object.values(combineData).forEach((time, index) => {
			this.timeTrackerService.submitTimeToTimesheet(time);
			if (Object.keys(combineData).length === index + 1) {
				this.showSubmit = false;
				this.submittingAll = false;
			}
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
