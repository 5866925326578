import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeTrackerComponent } from './time-tracker.component';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
	declarations: [TimeTrackerComponent],
	exports: [TimeTrackerComponent],
})
export class TimeTrackerModule {}
