import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsMultiSelectComponent } from './chips-multi-select.component';
import { MaterialModule } from '../../_modules/material.module';

@NgModule({
	declarations: [ChipsMultiSelectComponent],
	imports: [CommonModule, MaterialModule],
	exports: [ChipsMultiSelectComponent],
})
export class ChipsMultiSelectModule {}
