<div class="auth-page">
	<div class="form-block">
		<!-- Register Form -->
		<app-register></app-register>

		<!-- Remind Passowrd -->
		<div class="form-footer">
			<a routerLink="/login" class="text-light">Have an account? <span class="hyperlink">Login here.</span></a>
		</div>
	</div>
</div>
