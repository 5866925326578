import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/_shared/_services/storage.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	modalData: any;

	constructor(public storageService: StorageService) {}

	ngOnInit() {}
}
