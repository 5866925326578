import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TimesheetsService } from 'src/app/theme/pages/timesheets/_services/timesheets.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { AuthenticationService } from 'src/app/auth/_services';

@Component({
	selector: 'app-timesheets-approvals-table',
	templateUrl: './timesheets-approvals-table.component.html',
	styleUrls: ['../shared-timesheets.scss'],
})
export class TimesheetsApprovalsTableComponent implements OnInit, OnDestroy {
	isLoading = false;
	timesheetsSubsription: Subscription;
	timesheets: any[] = [];
	currentDate: any;
	usersList: any = {};

	// TABLE
	@ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
	dtOptions: any = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized = false;

	constructor(private router: Router, private timesheetsService: TimesheetsService, private authService: AuthenticationService) { }

	ngOnInit() {
		this.isLoading = true;
		this.currentDate = moment().format('DD-MM-YYYY');
		this.dtOptions = {
			processing: true,
			destroy: true,
			responsive: true,
		};
		this.timesheetsService.fetchUsersList().then(usersList => {
			// LIST OF USERS NAMES FOR TIMESHEET CREATEDBY FIELD
			this.usersList = usersList;

			this.timesheetsSubsription = this.timesheetsService.fetchApprovalTimesheets().subscribe((timesheets: any) => {
				const timesheetDedupe = [];
				this.timesheets = timesheets.filter(timesheet => {
					const checkName = `${timesheet.createdBy}-${timesheet.year}-${timesheet.month}-${timesheet.week}`;
					if (this.timesheetsService.authService.userDetails.uid !== timesheet.createdBy) {
						if (timesheetDedupe.indexOf(checkName) === -1) {
							timesheetDedupe.push(checkName);
							return timesheet;
						}
					}
				}).map((timesheet) => {
					return {
						createdByName: this.usersList[timesheet.createdBy],
						...timesheet
					};
				});

				console.log('timesheetDedupe', timesheetDedupe);
				console.log('timesheets', this.timesheets);

				this.isLoading = false;
				setTimeout(() => {
					this.rerender();
				});
			});
		});
	}

	rerender(): void {
		if (this.isDtInitialized) {
			this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
				this.dtTrigger.next();
			});
		} else {
			this.isDtInitialized = true;
			this.dtTrigger.next();
		}
	}

	navigateWeek(timesheet) {
		const date = moment(timesheet.dayDates.monday.seconds * 1000).format(`DD-MM-YYYY`);
		this.router.navigate([`/timesheets/edit/${timesheet.year}/${timesheet.week}/${date}/${timesheet.createdBy}`]);
	}

	ngOnDestroy() {
		this.timesheetsSubsription.unsubscribe();
		this.dtTrigger.unsubscribe();
	}
}
