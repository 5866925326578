<div class="auth-page">
	<div class="form-block">
		<!-- Tabs Titles -->

		<!-- Login Form -->
		<app-login *ngIf="!showHide && !passReset"></app-login>

		<!-- Register Form -->
		<app-register *ngIf="showHide && !passReset"></app-register>

		<!-- Reset Password Form -->
		<app-password-reset *ngIf="passReset"></app-password-reset>

		<!-- Remind Passowrd -->
		<div class="form-footer">
			<div (click)="toggleForms()">
				<span *ngIf="!showHide && !passReset">Need an account? <span class="hyperlink">Sign up here.</span></span>
				<span *ngIf="showHide && !passReset">Have an account? <span class="hyperlink">Login here.</span></span>
			</div>
			<div (click)="togglePassReset()" *ngIf="!showHide && !passReset" class="hyperlink">Forgot Password?</div>
			<div (click)="togglePassReset()" *ngIf="!showHide && passReset" class="hyperlink">Back to login</div>
		</div>
	</div>
</div>
