import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditLogService } from '../../_services/audit-log.service';

@Component({
	selector: 'app-audit-log',
	templateUrl: './audit-log.component.html',
	styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit, OnDestroy {
	@Input() type;
	@Input() projectId;
	@Input() teamId;
	@Input() userId;

	historyData = [];

	auditSubscription: Subscription;
	constructor(private auditLogService: AuditLogService) {}

	ngOnInit(): void {
		this.auditSubscription = this.auditLogService.getLog(this.type, this.userId, this.projectId, this.teamId).subscribe((data) => {
			this.historyData = data;
		});
	}

	ngOnDestroy(): void {
		if (this.auditSubscription) {
			this.auditSubscription.unsubscribe();
		}
	}
}
