<div>
	<form [formGroup]="timesForm" (change)="updateLocalStorage()">
		<div class="form-group">
			<select class="form-control" id="project" formControlName="project" (change)="filterProjectTimeCode()">
				<option value="">--- Select Project ---</option>
				<option *ngFor="let userProject of userProjects" [value]="userProject.id">{{ userProject.name }}</option>
			</select>
		</div>
		<div class="form-group" *ngIf="timesForm.value.project !== ''">
			<select class="form-control" id="time_code" formControlName="time_code">
				<option value="">--- Select Time Code ---</option>
				<option *ngFor="let projectTimeCode of projectTimeCodes" [value]="projectTimeCode.id">[{{ projectTimeCode.code }}] {{ projectTimeCode.description }}</option>
			</select>
		</div>
		<div class="form-group">
			<textarea type="text" class="form-control" id="description" formControlName="description" placeholder="Description" required></textarea>
		</div>
		<div class="form-group">
			<input type="text" class="form-control" id="tags" formControlName="tags" placeholder="Tags">
		</div>
		<div class="form-group">
			<div class="row">
				<div class="col-md-6">
					<input type="time" class="form-control" id="start" formControlName="start" placeholder="start">
				</div>
				<div class="col-md-6">
					<input type="time" class="form-control" id="end" formControlName="end" placeholder="end">
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="input-group">
				<input class="form-control" placeholder="yyyy-mm-dd" name="day" formControlName="day" ngbDatepicker #d="ngbDatepicker">
				<div class="input-group-append">
					<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><span class="fa fa-calendar"></span></button>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="row">
				<div class="col-md-6">
					<input type="text" class="form-control" id="timer" formControlName="timer" placeholder="timer" required (change)="updateTimer()">
				</div>
				<div class="col-md-6">
					<button *ngIf="!counterStarted && counter === 0" type="button" class="btn btn-outline-success btn-sm" (click)="startTimer()">
						Start
					</button>
					<button *ngIf="!counterStarted && counter > 0" type="button" class="btn btn-outline-warning btn-sm" (click)="startTimer()">
						Resume
					</button>
					<button *ngIf="counterStarted" type="button" class="btn btn-outline-danger btn-sm" (click)="stopTimer()">
						Stop
					</button>
				</div>
			</div>
		</div>
		<div class="form-check">
			<input class="form-check-input" type="checkbox" value="" id="billable" formControlName="billable">
			<label class="form-check-label" for="billable">
				Billable
			</label>
		</div>
		<div class="row pt-3">
			<div class="col-md-12">
				<button [disabled]="submitting || timesForm.invalid || counterStarted" type="button" class="btn btn-outline-primary btn-sm" (click)="addTime()">
					<span *ngIf="!submitting">Save</span>
					<span *ngIf="submitting">Saving</span>
				</button>
			</div>
		</div>
	</form>
</div>
<ng-container *ngIf="times && times.length > 0">
	<hr>
	<div class="row">
		<div class="col-md-12">
			<div class="list-scroller">
				<div class="list-group" *ngFor="let timer of times; let i = index">
					<div class="list-group-item list-group-item-action flex-column align-items-start"
						[ngClass]="(i%2 == 0) ? 'active' : ''">
						<div class="row">
							<div *ngIf="showSubmit" class="col-md-2">
								<button *ngIf="submittingIndividual !== timer.id && !submittingAll" type="button" class="btn btn-outline-primary btn-xs mt-3" title="Submit Row" (click)="submitRow(i)">
									<span class="fa fa-check"></span>
								</button>
								<button *ngIf="submittingIndividual === timer.id || submittingAll" type="button" class="btn btn-outline-primary btn-xs mt-3" title="Submitting Row">
									<span class="fa fa-refresh fa-spin"></span>
								</button>
							</div>
							<div [ngClass]="(showSubmit) ? 'col-md-10' : 'col-md-12'">
								<div class="d-flex w-100 justify-content-between">
									<h5 class="mb-1" style="font-size: small">{{ projectsList[timer.project] }} - {{ timeCodesList[timer.time_code] }}
									</h5>
									{{ formatTime(timer.timer) }}
								</div>
								<p class="mb-1">
									{{ timer.description }}
								</p>
								<small>{{ timer.start }} - {{ timer.end }}</small>
								<small class="pull-right">{{ timer.day.day }}/{{ timer.day.month }}/{{ timer.day.year }}</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12 mt-2">
			<span *ngIf="!showSubmit">
				<button type="button" class="btn btn-outline-primary btn-sm" (click)="submitShowHide()">Submit</button>
			</span>
			<span *ngIf="showSubmit">
				<button type="button" class="btn btn-outline-default btn-sm mr-2" (click)="submitShowHide()" [disabled]="submittingAll">Cancel</button>
				<button *ngIf="!submittingAll" type="button" class="btn btn-outline-primary btn-sm" (click)="submitAllRows()">Submit All</button>
				<button *ngIf="submittingAll" type="button" class="btn btn-outline-primary btn-sm">Submitting <span class="fa fa-refresh fa-spin"></span></button>
			</span>
		</div>
	</div>
</ng-container>