<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table-border" style="width: 100%">
	<thead>
		<tr>
			<th width="30" class="text-center">#</th>
			<th>Week</th>
			<th class="text-center">Actions</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let timesheet of timesheets; let i = index">
			<td class="text-center">{{ i + 1 }}</td>
			<td>{{ timesheet?.dayDates?.monday.toDate() | date: 'dd MMM yyyy' }} - {{ timesheet?.dayDates?.sunday.toDate() | date: 'dd MMM yyyy' }}</td>
			<td class="text-center">
				<a (click)="navigateWeek(timesheet)" title="Edit Week" style="cursor: pointer; padding: 0 5px"><i class="fa fa-edit"></i></a>
			</td>
		</tr>
	</tbody>
</table>
