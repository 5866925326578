import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/_services';

@Injectable({
	providedIn: 'root',
})
export class AuditLogService {
	user;
	constructor(public db: AngularFirestore, private authService: AuthenticationService) {
		this.authService.user.pipe(take(1)).subscribe((user) => (this.user = user));
	}

	addToLog(data) {
		data['created'] = new Date();
		data.createdByName = `${this.user.firstname} ${this.user.surname}`;
		return this.db.collection('history').add(data);
	}

	getLog(logType, userId?, projectId?, teamId?) {
		if (logType) {
			return this.db.collection('history', (ref) => ref.where('type', '==', logType).orderBy('created', 'desc')).valueChanges({ idField: 'id' });
		} else {
			if (userId) {
				return this.db.collection('history', (ref) => ref.where('userId', '==', userId).orderBy('created', 'desc')).valueChanges({ idField: 'id' });
			} else {
				if (projectId) {
					return this.db.collection('history', (ref) => ref.where('projectId', '==', projectId).orderBy('created', 'desc')).valueChanges({ idField: 'id' });
				} else {
					if (teamId) {
						return this.db.collection('history', (ref) => ref.where('teamId', '==', teamId).orderBy('created', 'desc')).valueChanges({ idField: 'id' });
					}
				}
			}
		}
	}
}
