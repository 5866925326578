<nav id="sidebar" class="navbar-dark bg-secondary" [ngClass]="{ hidden: sideNavService.hideSideNav }">
	<div class="nav-brand">
		<h3>
			<span class="fullname">Timesheets</span>
			<span class="shortname">TS</span>
		</h3>
	</div>
	<ul class="navbar-nav">
		<li class="nav-item">
			<a class="nav-link" routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
				><i class="fa fa-dashboard" title="Dashboard"></i> <span>Dashboard</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/timecodesgeneral/list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
				><i class="fa fa-clock-o" title="Time Codes"></i> <span>Time Codes</span></a
			>
			<ul class="nav-sub-menu">
				<li class="nav-item">
					<a class="nav-link" routerLink="/timecodesgeneral/list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">General</a>
				</li>
				<li class="nav-item action-item">
					<a class="nav-link" routerLink="/timecodes/list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Projects</a>
				</li>
			</ul>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/projects" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
				><i class="fa fa-sitemap" title="Projects"></i> <span>Projects</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/teams" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
				><i class="fa fa-briefcase" title="Teams"></i> <span>Teams</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/timesheets" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
				><i class="fa fa-calendar" title="Timesheet"></i> <span>Timesheets</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/timesheets/approvals" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
				><i class="fa fa-check" title="Approvals"></i> <span>Approvals</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/users/list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
				><i class="fa fa-users" title="Users"></i> <span>Users</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/reports/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
				><i class="fa fa-users" title="Users"></i> <span>Reports</span></a
			>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/products" routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: false }"><i class="fa fa-cubes" title="Products"></i> <span>Products</span></a>
			<ul class="nav-sub-menu">
				<li class="nav-item action-item">
					<a class="nav-link" routerLink="/products/list" routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }">List</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="/products/add" routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }">Add</a>
				</li>
			</ul>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/sales-pipeline" routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: false }"><i class="fa fa-bar-chart" title="Sales Pipeline"></i> <span>Sales Pipeline</span></a>
			<ul class="nav-sub-menu">
				<li class="nav-item action-item">
					<a class="nav-link" routerLink="/sales-pipeline/list" routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }">List</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="/sales-pipeline/add" routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }">Add</a>
				</li>
			</ul>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="/companies" routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: false }"><i class="fa fa-building-o" title="Companies"></i> <span>Companies</span></a>
			<ul class="nav-sub-menu">
				<li class="nav-item action-item">
					<a class="nav-link" routerLink="/companies/list" routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }">List</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" routerLink="/companies/add" routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: true }">Add</a>
				</li>
			</ul>
		</li>
	</ul>
</nav>
