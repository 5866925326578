/*
	https://zoaibkhan.com/blog/create-a-multi-select-chips-component-with-angular-material/
*/

import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChip, MatChipList } from '@angular/material/chips';
import { Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Component({
	selector: 'app-chips-multi-select',
	templateUrl: './chips-multi-select.component.html',
	styleUrls: ['./chips-multi-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ChipsMultiSelectComponent,
			multi: true,
		},
	],
})
export class ChipsMultiSelectComponent implements OnDestroy, AfterViewInit, ControlValueAccessor {
	@Input() options: { label: string; value: any }[] = [];
	@ViewChild(MatChipList)
	chipList!: MatChipList;
	value: string[] = [];
	disabled = false;
	selectionSubscription: Subscription;

	constructor() {}

	ngOnDestroy(): void {
		if (this.selectionSubscription) {
			this.selectionSubscription.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.selectChips(this.value);
		});

		this.selectionSubscription = this.chipList.chipSelectionChanges
			.pipe(
				delay(0),
				map((event) => event.source)
			)
			.subscribe((chip) => {
				if (chip.selected) {
					this.value = [...this.value, chip.value];
				} else {
					this.value = this.value.filter((o) => o !== chip.value);
				}

				this.propagateChange(this.value);
			});
	}

	writeValue(value: string[]): void {
		if (this.chipList && value) {
			this.selectChips(value);
		} else if (value) {
			this.value = value;
		}
	}

	onChange!: (value: string[]) => void;

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	propagateChange(values: any[]) {
		values = [...new Set(values)];
		if (this.onChange) {
			this.onChange(values);
		}
	}

	registerOnTouched(fn: any): void {
		// throw new Error('Method not implemented.');
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	toggleSelection(chip: MatChip) {
		if (!this.disabled) chip.toggleSelected();
	}

	selectChips(value: string[]) {
		this.chipList.chips.forEach((chip) => chip.deselect());

		const chipsToSelect = this.chipList.chips.filter((c) => value.includes(c.value));

		chipsToSelect.forEach((chip) => chip.select());
	}
}
