import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../_services';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { Subscription } from 'rxjs';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
	selector: 'app-login',
	templateUrl: './auth-login.component.html',
})
export class AuthLoginComponent implements OnInit {
	authSubscription: Subscription;
	userLoginForm: FormGroup;
	newUser = false; // to toggle login or signup form
	displayOptions = false;
	failedLogin: boolean = false;

	formErrors: FormErrors = {
		email: '',
		password: '',
	};
	validationMessages = {
		email: {
			required: 'Email is required.',
			email: 'Email must be a valid email',
		},
		password: {
			required: 'Password is required.',
			pattern: 'Password must include atleast one letter and one number.',
			minlength: 'Password must be at least 6 characters long.',
			maxlength: 'Password cannot be more than 25 characters long.',
		},
	};

	isLoading: boolean = false;

	// NEW USER REGISTERED REDIRECT
	decodeUserBase64: any;
	redirectUrl: any;

	constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router, private route: ActivatedRoute) {}

	ngOnInit() {
		this.buildForm();
		this.redirectUrl = '/dashboard';
		this.authSubscription = this.auth.user.subscribe((userDetails) => {
			this.route.params.subscribe((params: Params) => {
				if (params['uid']) {
					this.decodeUserBase64 = JSON.parse(atob(params['uid']));
					if (this.decodeUserBase64) {
						if (this.decodeUserBase64.type === 'resetPassword') {
							this.redirectUrl = `${this.decodeUserBase64.redirectUrl}/${btoa(this.decodeUserBase64.password)}`;
							if (userDetails) {
								this.router.navigate([this.redirectUrl]);
							} else {
								this.login(this.decodeUserBase64.email, this.decodeUserBase64.password);
							}
						}
					}
				} else {
					if (userDetails) {
						this.router.navigate([this.redirectUrl]);
					}
				}
			});
		});
	}

	login(email, password) {
		this.isLoading = true;
		this.auth
			.emailLogin(email, password)
			.then((result) => {
				if (result === undefined) {
					this.failedLogin = true;
				} else {
					this.failedLogin = false;
					this.router.navigate([this.redirectUrl]);
				}
			})
			.catch((error) => {
				if (error) {
					this.isLoading = false;
				}
			});
	}

	buildForm() {
		this.userLoginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]],
		});

		this.userLoginForm.valueChanges.subscribe((data) => this.onValuesChanged(data));
		this.onValuesChanged();
	}

	// Updates validation state on form changes.
	onValuesChanged(data?: any) {
		if (!this.userLoginForm) {
			return;
		}
		const form = this.userLoginForm;
		for (const field in this.formErrors) {
			if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'password')) {
				// clear previous error message (if any)
				this.formErrors[field] = '';
				const control = form.get(field);
				if (control && control.dirty && !control.valid) {
					const messages = this.validationMessages[field];
					if (control.errors) {
						for (const key in control.errors) {
							if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
								this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
							}
						}
					}
				}
			}
		}
	}
}
